import { NextPageWithLayout } from "shared/types";

import { TopPage, ProductExplorerLayout } from "~/features/products";

const Page: NextPageWithLayout = () => {
  return (
    <TopPage />
  );
};

Page.getLayout = (page) => <ProductExplorerLayout>{page}</ProductExplorerLayout>;

export default Page;
